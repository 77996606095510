import { Dialog, Button, Checkbox, IconButton, ListItemText, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const FilterDialog = function (props: { 
  handleClose: () => void,
  open: boolean,
  accountTypes: Array<string>,
  setAccountTypes: (value: Array<string>) => void,
  apply: () => void,
  title: string,
  setInit: (init: boolean) => void
}): JSX.Element {

  const { t } = useTranslation();
  const accountOptions = [t('all'), t('shifter'), t('sender'), t('admin')];

  const handleChange = (event: any, child: any) => {
    props.setInit(false);
    const value = event.target.value as string[];

    if (value.includes(accountOptions[0])) {
      if (value.length === 1 || child.props.value === accountOptions[0]) props.setAccountTypes(accountOptions);
      else props.setAccountTypes(value.filter((item) => item !== accountOptions[0]));      
    } else {
      if (value.length === 3) props.setAccountTypes(child.props.value === accountOptions[0] ? [] : accountOptions);
      else props.setAccountTypes(value.filter((item) => item !== accountOptions[0]));
    }
  };

  return (
    <Dialog onClose={props.handleClose} open={props.open}>
      <div style={{
        backgroundColor: "white",
        minHeight: '150px',
        maxHeight: '1000px',
        minWidth: '500px',
        overflow: 'auto',
        borderRadius: 5,
        padding: '10px',
        boxShadow: "1px 15px 8px #9E9E9E"
      }}>
        <div style={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          <h3 style={{ marginLeft: 5 }}>{props.title}</h3>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
            style={{ right: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <Select
            multiple
            value={props.accountTypes}
            onChange={handleChange}
            renderValue={(selected) => {
              if (selected.includes(accountOptions[0])) return props.accountTypes[0]
              return selected.join(", ")
            }}
            style={{ minWidth: 300 }}
          >
            {accountOptions.map((item: string, inx: number) => (
              <MenuItem key={inx} value={item}>
                <Checkbox color="primary" checked={props.accountTypes.includes(item)} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button variant='outlined' onClick={props.apply}>
          {t('apply')}
        </Button>
      </div>
    </Dialog>
  );
}

export default FilterDialog;
