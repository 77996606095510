/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, FormLabel, Input, TextField } from "@material-ui/core";
import { SettingsApi } from '../../services/api';

const NotificationsComponent: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const { getCommission, setCommission } = SettingsApi();
  const [value, setValue] = useState<number>(0);
  const [newValue, setNewValue] = useState<any>('');

  useEffect(() => {
    (async () => {
      const commission = await getCommission();
      setValue(commission);
    })();
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '100%', margin: '30px' }}>
          <FormLabel style={{ fontSize: 15 }}>{`${t('deal_percentage')} ${value}%`}</FormLabel>
          <FormGroup>
            <TextField
              id='commission'
              label={t('commission')}
              type='text'
              value={newValue}
              style={{ width: '15%', marginBottom: '20px' }}
              onChange={event => {
                setNewValue(event.target.value);
              }}
            />
            <Button
              color='primary'
              variant='contained'
              style={{ width: '15%' }}
              onClick={async () => {
                if (!/^[-]?\d*\.?\d*$/.test(newValue)) {
                  alert(t('wrong_value_entered'));
                  return;
                }
                const res = await setCommission(newValue);
                if (res !== null && res !== undefined) {
                  const commission = await getCommission();
                  if (commission) setValue(commission);
                }
                setNewValue('');
              }}
              disabled={!newValue}>
              {t('change_commission')}
            </Button>
          </FormGroup>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotificationsComponent;
